import React from 'react';
import {
    Button,
    Col,
    Row } from 'react-bootstrap';
import { Helmet } from "react-helmet";
import { FaChevronCircleRight } from "react-icons/fa";
import Banner from './Banner';  // Import Banner component from routes (components) folder.

export default function Home() {
  return (
    <div>
        {/* The Helmet reusable React component will manage all of your changes to the document head. */}
        <Helmet>
            <meta charSet="utf-8" />
            {/* The title of browser tab is for home page */}
            <title>ILA MS OY - Solutions</title>
            {/* Home page SEO */}
            <meta name="title" content="ILA MS | Identify, Track & Locate wheeled cargo in Real-Time in ships." />;
            <meta name="description" content="ILA MS provides a real-time location system for wheeled cargo in ships, helping improve efficiency, save cost, and respond to emergencies promptly." />;
            <meta name="keywords" content="Real-Time Location, Digitize Cruise Ships, Real-time data on wheeled cargo for decision-making" />;
        </Helmet>
        {/* Home page banner section. Called Banner component from routes (components) folder. */}
        <Banner />
        <main id='solutions'>
            <h1 className='center'>ILA MS SOLUTIONS</h1>
            <div className='ilams-solutions'>
                <Row>
                    <Col sm={7}>
                        <img src='images/solutions/SpaceOptimization.png' alt='Increase efficiencies and save costs icon' />
                    </Col>
                    <Col sm={5} className='solution-items'>
                        <h1>Unloading & Loading Prioritization</h1>
                    </Col>
                </Row>
                <Row>
                    <Col sm={7}>
                        <img src='images/solutions/AllocatedDeckSpace.png' alt='Increase efficiencies and save costs icon' />
                    </Col>
                    <Col sm={5} className='solution-items'>
                        <h1>Terminal & Deck Space Optimization</h1>
                    </Col>
                </Row>
                <Row>
                    <Col sm={7}>
                        <img src='images/solutions/Prioritization.png' alt='Increase efficiencies and save costs icon' />
                    </Col>
                    <Col sm={5} className='solution-items'>
                        <h1>Automated Deck Directing System</h1>
                    </Col>
                </Row>
                <Row>
                    <Col sm={7}>
                        <img src='images/solutions/Identification-in-real-time.png' alt='Increase efficiencies and save costs icon' />
                    </Col>
                    <Col sm={5} className='solution-items'>
                        <h1>Real-time Monitoring Analytics</h1>
                    </Col>
                </Row>
            </div>
        </main>
        <hr />
        <main>
            <div className='center interested'>
                <h1>INTERESTED? <br /> LET’S TALK ABOUT IT</h1>
                <Row>
                    {/* <Col>
                        <Button >Request Demo <FaChevronCircleRight size={25} /></Button>
                    </Col> */}
                    <Col>
                        <Button >
                            <a href='https://calendly.com/maci_/ila-ms' title="Click here to schedule meeting" target="_blank" rel="noreferrer">
                                Schedule Meeting <FaChevronCircleRight size={25} />
                            </a>
                        </Button>
                    </Col>
                </Row>
            </div>
        </main>
    </div>
  );
}
