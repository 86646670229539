import {
    Container,
    Navbar,
    Nav,
    } from 'react-bootstrap';
import { Outlet } from "react-router-dom";

export default function Header() {
    return(
        <div>
            <Navbar fixed="top" collapseOnSelect expand="lg" bg="white" variant="white">
                <Container>
                    <Navbar.Brand href="/">
                        <img src="ilams-logo.png" alt="ILA MS logo"/>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link href="/">Home</Nav.Link>
                            <Nav.Link href="/about">About</Nav.Link>
                            <Nav.Link href="/contact">Contact</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <div className="navbar-bottom"></div>
            {/* Show navigation page using Outlet */}
            <Outlet />
        </div>
    );
}
