import React from 'react';
import "../App.css";
import {
  // Card,
  Col,
  Row, NavLink } from 'react-bootstrap';
import { Helmet } from "react-helmet";
import {TbArrowBottomTail} from "react-icons/tb";
// import { AiOutlineMail } from 'react-icons/ai';
// import { FcBusinessman } from 'react-icons/fc';
// import { BsTelephone } from 'react-icons/bs';


export default function About() {
  return (
    <div>
        <Helmet>
            <meta charSet="utf-8" />
            {/* The title of browser tab is for About page */}
            <title>ILA MS OY - About </title>
            {/* About page SEO */}
            <meta name="title" content="ILA MS | The Global Gamechanger in Real-time Location Systems for the ferry industry" />;
            <meta name="description" content=" ILA MS Real-Time Location System combines Hardware & Software in new ways that put us in a position to bring revolution to the maritime and ferry industry." />;
            <meta name="keywords" content="Innovative Real-time Location Systems, Maritime/ferry industry, Port of tomorrow" />;
        </Helmet>

       <div className=' about-text text-center ' >
              <h1>ABOUT</h1>
              <h3>IN A NUTSHELL</h3>
       </div>
        <div className='container text-center upper-section mb-5' id='nutshell'>
            <div className='section-journey'>
              <div className='about-nutshell'>
                {/* <p>It all started with the founders of the company taking a trip from Turku to Stockholm. The journey was so cumbersome that they decided to solve challenges that persist in the ferry industry to make passenger's journeys convenient and easy and help ferry owners improve their operations.Some of the challenges identified are:</p> */}
                <p>The ferry industry has long been plagued with problems that have made it difficult for passengers to have a convenient and seamless journey. One of the key issues that has been identified is the cumbersome nature of the travel experience, which can leave passengers feeling frustrated and exhausted. This problem has been further compounded by a lack of innovation in the industry, with many ferry operators failing to adopt modern technologies and processes that could help to streamline operations and improve the customer experience. As a result, many ferry owners are struggling to stay competitive in an increasingly crowded marketplace and need innovative solutions that can help them stand out from the crowd and attract more customers. Some of the challenges identified are: </p>
                <div className='container my-5'>
                  <Row className='nutshell-point my-3 '>
                      <Col sm={3} >
                      <img src='images/about_nutshell/icon-1.png'  alt='' />
                      </Col>
                      <Col sm={9} className='' >
                          <h5> The massive amount of workload on ship crew during loading and unloading.</h5>
                      </Col>
                  </Row>
                  <Row className='nutshell-point my-3'>
                      <Col sm={3} >
                      <img src='images/about_nutshell/icon-2.png' alt='' />
                      </Col>
                      <Col sm={9} className='' >
                          <h5> Slow and inefficient wheeled cargo flow and handling.</h5>
                      </Col>
                  </Row>
                  <Row className='nutshell-point my-3'>
                      <Col sm={3} >
                      <img src='images/about_nutshell/icon-3.png' alt='' />
                      </Col>
                      <Col sm={9} className='' >
                          <h5> Inefficient use of space either inside ships and on terminal premises.</h5>
                      </Col>
                  </Row>
              </div>
              </div>
              <h1><TbArrowBottomTail/></h1>
              <div className='about-nutshell '>
                <p>To get the most out of our solution, we had to reinvent a design for ports (Ferry terminals, more specifically). The existing structure has many inconsistencies, and amongst the prominent ones:</p>
                <div className='container my-5' >
                <Row className='nutshell-point my-3'>
                    <Col sm={3} >
                    <img src='images/about_nutshell/icon-4.png'  alt='' />
                    </Col>
                    <Col sm={9} className='' >
                        <h5> Passenger journey starts from their car and ends inside the ferry (Port is conceived in its plain meaning as a port).</h5>
                    </Col>
                </Row>
                <Row className='nutshell-point my-3'>
                    <Col sm={3} >
                    <img src='images/about_nutshell/icon-5.png'  alt='' />
                    </Col>
                    <Col sm={9} className='' >
                        <h5>First arrive at the port is first to board the ship (a port is considered a catwalk).</h5>
                    </Col>
                </Row>

                </div>
              </div>
          </div>
          {/* <div className='today-section '>
            <h1>TODAY</h1>
            <p className='paraOne'>We have been pushing forward to reach the audacious goal that we set for ourselves. We present you with a few critical metrics to know how far we have come </p>
              <Row>
                  <Col className='px-5' sm={6}>
                      <img src='images/icons/concepticon.png' alt='Validate Business Concept' />
                      <h3>Validate Business Concept</h3>
                      <p>We pitched our idea to business Finland and they saw the potential. Hence, we were rewarded innovation voucher, the value of €5000.</p>
                  </Col>
                  <Col  sm={6} className='px-5'>
                    <img src='images/icons/analysisIcon.png' alt='Identify Potential Market' />
                    <h3>Identify Potential Market</h3>
                        <p>We conducted freedom to operate (FTO) analysis with the help of analysts to identify and navigate potential entry barriers in the market. the fto analysis result was positive.</p>
                  </Col>
              </Row>
              <Row>
                  <Col sm={6} className='p-5'>
                      <img src='images/icons/searchIcon.png' alt='Novelty Search' />
                      <h3>Novelty Search</h3>
                      <p>Since the first condition of patentability isthat the invention must be new, we con ducted research with consultant to help us validate the novelty of our idea. and the result showed that no one in the market has brought such innovation until now. therefore, our innovation (ila ms) was qualified as a novel.</p>
                  </Col>
                  <Col sm={6} className='p-5'>
                    <img src='images/icons/userIcon.png' alt='Minimum viable product (mvp)' />
                    <h3>Minimum Viable Product (MVP)</h3>
                    <p> We successfully developed an MVP where we tested the working mechanism of our technologies thoroughly. we demo it with other stakeholders and non-users of this innovation to test usability and usefulness</p>
                  </Col>
                </Row>
                <Row>
                    <Col sm={12}className=' pad  justify-content-center'>
                        <img src='images/icons/folderIcon.png' alt='Patentability' />
                        <h3>Patentability</h3>
                        <p>This is one of the metrics which we are  pursuing in conjunction with product development (MVP) as the process is lengthy and expensive.</p>
                    </Col>
                </Row>
          </div> */}
          {/* <div className='future-section'>
              <h1>FUTURE</h1>
              <div className='download-content'>
                <p>We are building the port of tommorow. Download our full Blue Ocean Canvas here.</p>
                <button className="download-btn" type="submit">Download <BsFillArrowDownCircleFill className="btnIcon" color="#000099" fontSize="1.5em"></BsFillArrowDownCircleFill> </button>
              </div>
          </div> */}
          <hr className='team-hr'></hr>
          <div className='team-section' id='team'>
              {/* <div className='team-text'>
                <h1>OUR TEAM</h1>
                <h3>The invisible fabric that holds us together</h3>
                <p className='team-pera'>The purpose of a buisness is to lead the world towards betterment through positive contribution! And this is our shared goal and vision.</p>
              </div> */}
              {/* <section className="pt-5 pb-5">
                <div>
                  <div className="col-12">
                    <div id="carouselExampleIndicators2" className="carousel slide" data-ride="carousel">
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <div className="row justify-content-center">
                                    <div className="col-md-4 col-lg-4 mb-3 ">
                                      <div className="card team-card">
                                        <Card.Img className='team-img' variant="top" src="images/team/Meck_.jpg" />
                                        <Card.Body>
                                          <h3>Micipsa Mechiche</h3>
                                          <h6> <FcBusinessman/> CEO & FOUNDER</h6>
                                          <h6> <AiOutlineMail/> maci@ilams.io</h6>
                                          <h6> < BsTelephone /> +358449178392</h6>
                                        </Card.Body>
                                        <Card.Body>
                                          <Card.Link className='team-link' target="_blank" href="https://www.linkedin.com/in/maci-mechiche-7058a585/">Linkedin</Card.Link>
                                        </Card.Body>
                                      </div>
                                    </div>
                                    <div className="col-md-4 col-lg-4 mb-3">
                                      <div className="card team-card">
                                        <Card.Img className='team-img' variant="top" src="images/team/Surya.jpg" />
                                        <Card.Body>
                                          <h3>Surya Yadav</h3>
                                          <h6> <FcBusinessman/> CMO & C0-FOUNDER</h6>
                                          <h6> <AiOutlineMail/>  surya@ilams.io</h6>
                                          <h6> < BsTelephone /> +358469375029</h6>
                                        </Card.Body>
                                        <Card.Body>
                                          <Card.Link className='team-link' target="_blank" href="https://www.linkedin.com/in/suryakant-yadav-4550358a/">Linkedin</Card.Link>
                                        </Card.Body>
                                      </div>
                                    </div>
                                    <div className="col-md-4 col-lg-4 mb-3">
                                        <div className="card team-card">
                                          <Card.Img className='team-img' variant="top" src="images/team/Neeraj.jpg" />
                                          <Card.Body>
                                            <h3>Neeraj Agrawal</h3>
                                            <h6> <FcBusinessman/> CTO & C0-FOUNDER</h6>
                                            <h6> <AiOutlineMail/> neeraj@ilams.io</h6>
                                            <h6> < BsTelephone /> +919970316425</h6>
                                          </Card.Body>
                                          <Card.Body>
                                            <br />
                                          </Card.Body>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-lg-4 mb-3">
                                        <div className="card team-card">
                                        <Card.Img className='team-img ' style={{ backgroundColor: 'white' }}variant="top" src="images/team/Saiful.jpg" />
                                        <Card.Body>
                                          <h3>Md Saiful Islam</h3>
                                          <h6> <FcBusinessman/> Jr. SOFTWARE ENGINEER</h6>
                                          <h6> <AiOutlineMail/> saiful@ilams.io</h6>
                                          <h6> < BsTelephone /> +358465910730</h6>
                                        </Card.Body>
                                        <Card.Body>
                                          <Card.Link className='team-link' target="_blank" href="https://www.linkedin.com/in/mdsaifulislam0705/">Linkedin</Card.Link>
                                        </Card.Body>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-lg-4 mb-3">
                                        <div className="card team-card">
                                        <Card.Img className='team-img ' style={{ backgroundColor: 'white' }}variant="top" src="images/team/Sajal.jpg" />
                                        <Card.Body>
                                          <h3>Abdullah Sajal</h3>
                                          <h6> <FcBusinessman/> Jr. SOFTWARE ENGINEER</h6>
                                          <h6> <AiOutlineMail/> abdullah@ilams.io</h6>
                                          <h6> < BsTelephone /> +358465896030</h6>
                                        </Card.Body>
                                        <Card.Body>
                                          <Card.Link className='team-link' target="_blank" href="https://www.linkedin.com/in/maa-sajal/">Linkedin</Card.Link>
                                        </Card.Body>
                                        </div>
                                    </div>
                                  </div>
                              </div>
                          </div>
                        </div>
                      </div>
                    </div>
              </section> */}

              <div className='join-team'>
                  <h1>JOIN OUR TEAM</h1>
                  <p>If you are an individual who is looking for an opportunity to impact the world through engineering, marketing, AI <br/> or investment, contact us. </p>
                  <button className="contact-btn" type="submit"><NavLink href="/contact#contact-details">Contact us</NavLink></button>
              </div>
          </div>
        </div>
    </div>
  );
}


