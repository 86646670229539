import React from 'react'
import {
  Col,
  Row} from 'react-bootstrap';
import ContactForm from './ContactForm';
import { Helmet } from "react-helmet";
// import { FaChevronCircleDown } from "react-icons/fa";
// import { CgChevronDownR } from "react-icons/cg";


export default function ContactPage() {
  return (
    <div id="contact-details">
        <Helmet>
            <meta charSet="utf-8" />
            {/* The title of browser tab is for Contact page */}
            <title>ILA MS OY - Contact </title>
            {/* Contact page SEO */}
            <meta name="title" content="ILA MS | Real-Time Location System for ferries"/>;
            <meta name="description" content="The novel combination of hardware and software allows ferry owners to digitize ships quickly which improves efficiency and save cost. Best of all, it can be implemented quickly. Contact us today!" />;
        </Helmet>
      <div className='contact-page '>
        <main>
        <div className='contact-text text-center'>
          <h1>CONTACT</h1>
          <h3>Let's talk</h3>
        </div>
        <Row>
          <Col sm={12} lg={4}>
            <Row className='boxOne'>
              <a href='callto:+358449178392'>
                <img className='icon-img center' src='images/icons/phoneIcon.png' alt='Phone icon' />
                <span>+358449178392</span>
              </a>
            </Row>
            <Row className='boxTwo'>
              <a href='mailto:maci@ilams.io'>
                <img className='icon-img center' src='images/icons/messageIcon.png' alt='Message icon' />
                <span>maci@ilams.io</span>
              </a>
            </Row>
            <Row className='boxThree'>
              <div className='pt-3'>
              <a href='https://goo.gl/maps/Git2wykC9bQnPTw98' target='_blank' rel="noreferrer" >
                <img className='icon-img center' src='images/icons/locationIcon.png' alt='Location icon' />
                <span>Åkerlundinkatu 8, 33100 Tampere</span>
              </a>
              <div className='map'>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1904.014014137035!2d23.774728316070156!3d61.49612308246871!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x468edf83ba8f48a7%3A0x9bf6085e3dc93600!2sPlatform6!5e0!3m2!1sen!2sfi!4v1656494751512!5m2!1sen!2sfi" width="100%" height="266" title="ILA MS OY" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
              </div>
              </div>
            </Row>
          </Col>
          <Col sm={12} lg={8}>
            <div className='contact-input'>
              <Col sm={2} xs={3}><img src='images/icons/textBoxIcon.png' alt='Contact form input icon' /></Col>
              <div className='contact-form'>
                <ContactForm />
              </div>
            </div>
          </Col>
        </Row>
        {/* <div className='contact d-flex row  justify-content-center mb-5'>
          <div className='contact-info col-lg-4 col-sm-12'>
          <div className='contact-number'>
              <div className='boxOne d-flex   '>
                <img className='icon-img center' src='images/icons/phoneIcon.png' alt='' />
                <p className='pt-3' >+358449178392</p>
              </div>
              <div className='boxTwo d-flex '>
                <img className='icon-img center' src='images/icons/messageIcon.png' alt='' />
                <p className='pt-3' >maci@ilams.io</p>
              </div>
             <div className=''>
              <div className='boxThree d-flex '>
                  <img className='icon-img center' src='images/icons/locationIcon.png' alt='' />
                  <p className='pt-3' >Åkerlundinkatu 8, 33100 Tampere</p>
                </div>
                <div className='map'>
                  <iframe   src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1904.014014137035!2d23.774728316070156!3d61.49612308246871!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x468edf83ba8f48a7%3A0x9bf6085e3dc93600!2sPlatform6!5e0!3m2!1sen!2sfi!4v1656494751512!5m2!1sen!2sfi" width="100%" height="266" title="ILA MS OY" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
             </div>
          </div>
        </div>
        <div className='contact-input mb-5  row p-3 col-lg-7 col-sm-12 '>
            <div className='contactForm '>
                    <Form className=''>
                    <img className='iconImg ' src='images/icons/textBoxIcon.png' alt='' />
                        <Row>
                            <Col sm={12} lg={4}>
                                <Form.Group className="mb-3" controlId="formGridName">
                                    <Form.Control type="text" placeholder="Name" required />
                                </Form.Group>
                            </Col>
                            <Col sm={12} lg={4}>
                                <Form.Group className="mb-3" controlId="formGridPhone">
                                    <Form.Control type="text" placeholder="Phone" required />
                                </Form.Group>
                            </Col>
                            <Col sm={12} lg={4}>
                                <Form.Group className="mb-3" controlId="formGridHeading">
                                    <Form.Control type="email" placeholder="Email" required />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Form.Group className="mb-3" controlId="formGridSubject">
                            <Form.Select aria-label="Default select example">
                                <option>Subject</option>
                                <option value="">Subject name 1</option>
                                <option value="">Subject name 2</option>
                                <option value="">Subject name 3</option>
                                <option value="">Subject name 4</option>
                                <option value="">Subject name 5</option>
                                <CgChevronDownR />
                                <FaChevronCircleDown />
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formGridMessage">
                            <Form.Control as="textarea" rows={8} placeholder="Message..." required />
                        </Form.Group>
                        <div className='d-flex justify-content-end' >
                            <Button variant="primary" type="submit">Send</Button>
                        </div>
                    </Form>
              </div>
          </div>
        </div> */}
        </main>
      </div>
    {/* <div className='container faq-question ' id="faq">
      <div>
        <div className='faq-text text-center m-5'>
          <h1>FAQs</h1>
          <p>Frequently Asked Questions</p>
        </div>
        <Row className='faq-section'>
          <Col sm={4}>
            <div className='question mb-5'>
              <h3>Jump to a question:</h3>
              <p><FiArrowDownRight color="white" fontSize="1.2em "/>Which stage of startup we are?</p>
              <p><FiArrowDownRight color="white" fontSize="1.2em" />What have you accomplished so far?</p>
              <p><FiArrowDownRight color="white" fontSize="1.2em"/>What is your financial forecast?</p>
              <p><FiArrowDownRight color="white" fontSize="1.2em"/>How much capital have you collected so far?</p>
              <p><FiArrowDownRight color="white" fontSize="1.2em"/>Who else is backing you?</p>
            </div>
          </Col>
          <Col sm={8}>
            <div className='answer'>
              <h3>Which stage of startup we are?</h3>
              <p>We have validated our concept and now we are in seed funding
                stage where we are looking for businesses, Investors or Individuals
                to back us up for product development.</p>
              <hr></hr>
              <h3>What have you accomplished so far?</h3>
              <p>We have conducted freedom To operate analysis for market entry validation,novelty search to establish uniqueness of our invention. Please check history section for detailed description.</p>
              <hr></hr>
              <h3>What is your financial forecast?</h3>
              <p>With an initial capital of €100k (optimistically speaking), we will break even by selling our product to onlv 7 shins of any type.We are happy to go through our financials again together</p>
              <hr></hr>
              <h3>How much capital have you collected so far?</h3>
              <p>We have invested all of our savings and looking for a little push to move forward with a government grant that requires €30k as initial capital</p>
              <hr></hr>
              <h3>Who else is backing you?</h3>
              <p>Our business concept has been approved by Business Finland and we have received a commitment for a grant on the condition <br></br> Also, we are hoping it will be you!</p>

            </div>
          </Col>
        </Row>
      </div>
    </div> */}
  </div>
  )
}
