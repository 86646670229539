import React from 'react';
import {
    Container,
    Row,
    Col,
    Button } from 'react-bootstrap'

export default function Banner() {
  return (
    <div>
        <div className='banner'>
        <Container fluid>
            <Row>
                <Col sm={12} lg={7}>
                    <img src='images/ship-pic.png' alt='Ship Demo' />
                </Col>
                <Col sm={12} lg={4} className="banner-btn">
                    <h1>Identify Track & <br /> Locate in real-time</h1>
                    <Row>
                        <Col>
                            <Button >
                                <a href="https://calendly.com/maci_/ila-ms" title="Click here to schedule meeting" target="_blank" rel="noreferrer">Book Demo</a>
                            </Button>
                        </Col>
                        {/* <Col>
                            <Button>Join Pilot</Button>
                        </Col> */}
                    </Row>
                </Col>
            </Row>
        </Container>
        </div>
    </div>
  );
}
