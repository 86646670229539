import React from 'react';
import {
    Button,
    Col,
    Row,
    Form } from 'react-bootstrap'
    import emailjs from '@emailjs/browser';
    import { useState } from 'react';

// The ContactForm component will be used in our contact page and home page (As we need)
export default function ContactForm() {
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    
    const submit = () => {
        if (name && email && message) {
            const serviceId = 'service_ql4s93g';
            const templateId = 'template_wzd3iqm';
            const userId = '4rXT6H2h04kJM0GcX';
            const templateParams = {
                name,
                phone,
                email,
                subject,
                message
            };
            emailjs.send(serviceId, templateId, templateParams, userId)
            .then(res=>{
                console.log(alert("Thank you! we have received your message and one of our team member will contact you soo!"));
            }).catch(err=>console.log(err));

            setName('');
            setPhone('')
            setEmail('');
            setMessage('');
            setSubject('')
        } else {
            alert('Please fill in all fields.');
        }
    }
    return(
        <div>
            <div className='contactForm'>
                <Form>
                    <Row>
                        <Col sm={12} lg={4}>
                            <Form.Group className="mb-3" controlId="formGridName">
                                <Form.Control type="text" placeholder="Name" required value={name} onChange={e => setName(e.target.value)} />
                            </Form.Group>
                        </Col>
                        <Col sm={12} lg={4}>
                            <Form.Group className="mb-3" controlId="formGridPhone">
                                <Form.Control type="text" placeholder="Phone" value={phone} onChange={e => setPhone(e.target.value)} required />
                            </Form.Group>
                        </Col>
                        <Col sm={12} lg={4}>
                            <Form.Group className="mb-3" controlId="formGridEmail">
                                <Form.Control type="email" placeholder="Email" required value={email} onChange={e => setEmail(e.target.value)} />
                            </Form.Group>
                        </Col>

                        <Col sm={12} lg={12}>
                            <Form.Group className="mb-3" controlId="formGridSubject">
                                <Form.Control type="text" placeholder="Subject" value={subject} onChange={e => setSubject(e.target.value)} required />{/* Customer will type the subject here */}
                            </Form.Group>
                        </Col>
                    </Row>
                    {/* <Form.Group className="mb-3" controlId="formGridSubject">
                        <Form.Select aria-label="Default select example" required>
                            <option>Subject</option>
                            <option value="">Subject name 1</option>
                            <option value="">Subject name 2</option>
                            <option value="">Subject name 3</option>
                            <option value="">Subject name 4</option>
                            <option value="">Subject name 5</option>
                        </Form.Select>
                    </Form.Group> */}
                    <Form.Group className="mb-3" controlId="formGridMessage">
                        <Form.Control as="textarea" rows={8} placeholder="Message..." value={message} onChange={e => setMessage(e.target.value)} required />
                    </Form.Group>
                    <div className='d-flex justify-content-end' >
                        <Button onClick={submit} >Send</Button>  
                    </div>
                </Form>
            </div>
        </div>
    )
}
