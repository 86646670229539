import React from 'react';
import {
    Container,
    Row,
    Col,
    NavLink }
    from 'react-bootstrap';
import { Twitter, Linkedin } from 'react-bootstrap-icons';

export default function Footer() {
  return (
    <div>
        <div className='footer'>
            <Container fluid>
            <Row className='footer-container'>
                <Col sm={12} lg={6} className="footer-left">
                    <NavLink href='/'><img src='images/ILA-MS-logo-transparent-background-HD.png' alt='ILA MS logo' /></NavLink>
                    {/* <footer className='center'>&copy; 2022 ILA MS. All Rights Reserved.</footer> */}
                </Col>
                <Col sm={12} lg={6} className="footer-right">
                    <Row className="footer-link">
                        <Col>
                            <h2>Home</h2>
                            <NavLink href="/#solutions" >ILA MS solutions</NavLink>
                            {/* <NavLink href="/#investor">Investor</NavLink> */}
                        </Col>
                        <Col>
                            <h2>About</h2>
                            <NavLink href="/about#nutshell">In a Nutshell</NavLink>
                            <NavLink href="/about#team">Our team</NavLink>
                        </Col>
                        <Col>
                            <h2>Contact</h2>
                            <NavLink href="/contact#contact-details">Contact details</NavLink>
                            {/* <NavLink href="/contact#faq">FAQs</NavLink> */}
                        </Col>
                    </Row>
                    <div className='footer-middle'></div>
                    <Row className="icons">
                        <Col sm={2} xs={2}>
                            <a href='https://twitter.com/ILAMS14' target='_blank' rel="noreferrer"><Twitter size={50} /></a>
                        </Col>
                        <Col sm={2} xs={2}>
                            <a href='https://www.linkedin.com/company/ila-ms/' target='_blank' rel="noreferrer"><Linkedin size={50} /></a>
                        </Col>
                    </Row>
                </Col>
                <Col>
                    <footer>&copy; 2023 ILA MS. All Rights Reserved.</footer>
                </Col>
            </Row>
            </Container>
        </div>
    </div>
  );
}
