import './App.css';
import './Responsive.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import {
  Routes,
  Route,
} from "react-router-dom";

import Header from './routes/Header';
import Home from './routes/Home';
import About from './routes/About';
import Contact from './routes/Contact';
import Footer from './routes/Footer';

function App() {
  return (
    <div className="App">
        <Routes>
          <Route path="/" element={<Header />}>
            <Route index element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/*"
              element={
                <main style={{ padding: "1rem" }}>
                  <p>There's nothing here!</p>
                </main>
              } />
          </Route>
        </Routes>
      {/* Footer call here */}
      <Footer />
    </div>
  );
}
export default App;
